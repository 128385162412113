import { LogLevel } from '@workerbase/domain/log';
import { TabActiveColorType, CategoryOption } from '@workerbase/types/ListConfig';
import { LogPayload } from '@workerbase/types/MongoDB/Log';

export interface ProjectLog {
  id: string;
  level: LogLevel;
  action: string;
  payload: LogPayload;
  meta: { createdAt: Date };
}

export enum ProjectLogView {
  LEVEL = 'level',
  NO_KEY = 'no-key',
}

export const ProjectLogViewOptions: Record<ProjectLogView, CategoryOption[]> = {
  [ProjectLogView.LEVEL]: [
    {
      key: LogLevel.ERROR,
      titleTranslationKey: 'logs.level.error',
      count: 0,
      activeColor: TabActiveColorType.DANGER,
    },
    {
      key: LogLevel.INFO,
      titleTranslationKey: 'logs.level.info',
      count: 0,
      activeColor: TabActiveColorType.GREEN,
    },
    // {
    //   key: LogLevel.DEBUG,
    //   titleTranslationKey: 'logs.level.debug',
    //   count: 0,
    //   activeColor: TabActiveColorType.DEFAULT,
    // },
    // {
    //   key: LogLevel.TRACE,
    //   titleTranslationKey: 'logs.level.trace',
    //   count: 0,
    //   activeColor: TabActiveColorType.GRAY,
    // },
  ],
  [ProjectLogView.NO_KEY]: [],
};
